/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGithub, faTwitter } from "@fortawesome/free-brands-svg-icons"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 50, height: 50, quality: 95) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
            github
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author
  const social = data.site.siteMetadata?.social

  const avatar = data?.avatar?.childImageSharp?.fixed

  return (
    <div className="bio">
      {avatar && (
        <Image
          fixed={avatar}
          alt={author?.name || ``}
          className="bio-avatar"
          imgStyle={{
            borderRadius: `50%`,
          }}
        />
      )}
      {author?.name && (
        <p>
          <strong>{author.name}</strong>
          <p>{author?.summary || null}</p>

          <div style={{ position: "relative", marginTop: "10px", }}　>
            <div style={{ position: "absolute", width: "100%", top: "0", left: "0", }} >
              <a style={{ boxShadow: "none", }} href={`https://twitter.com/${social?.twitter || ``}`}>
                <FontAwesomeIcon
                  style={{ height: "1.5em", width: "1.5em", marginRight: "5", }}
                  color="#3eaded"
                  icon={faTwitter}
                />
              </a>
              <a style={{ boxShadow: "none", }} href={`https://github.com/${social?.github || ``}`}>
                <FontAwesomeIcon
                  style={{ height: "1.5em", width: "1.5em", marginRight: "5", }}
                  color="#333"
                  icon={faGithub}
                />
              </a>
            </div>
          </div>
        </p>
      )}
    </div>
  )
}

export default Bio
